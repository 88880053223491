import {ResponsiveContainer, Tooltip, PieChart, Pie, Cell} from 'recharts';

import networkImage from 'assets/images/network.svg';
import {useState, useEffect, useMemo, lazy, Suspense} from 'react';
import {
  Box,
  Button,
  Skeleton,
  Text,
  VStack,
  Image,
  HStack,
  Circle,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';

import {DialogFallback, DialogListSw} from 'common/components';

const DEFAULT_COLORS = [
  'var(--chakra-colors-teal-400)',
  'var(--chakra-colors-orange-500)',
  'var(--chakra-colors-purple-500)',
];

function SmartwatchPie({
  title,
  dateRange,
  typeCheck,
  data = [],
  summary = {
    totalPatient: 0,
    healthy: 0,
    handling: 0,
    notMeasure: 0,
  },
  isLoaded = false,
}: {
  title: string;
  dateRange: string;
  typeCheck: any;
  data: any[];
  summary: {
    totalPatient: number;
    healthy: number;
    handling: number;
    notMeasure: number;
  };
  isLoaded?: boolean;
}): JSX.Element {
  const [statusPatients, setStatusPatients] = useState();

  const {
    isOpen: isOpenDialogListSw,
    onOpen: onOpenDialogListSw,
    onClose: onCloseDialogListSw,
  } = useDisclosure();

  const onOpenDialog = (patientStatus: any): void => {
    setStatusPatients(patientStatus);
    onOpenDialogListSw();
  };

  const onCloseDialog = (): void => {
    onCloseDialogListSw();
  };
  return (
    <Skeleton isLoaded={isLoaded} height="100%">
      <Suspense fallback={<DialogFallback />}>
        {isOpenDialogListSw && (
          <DialogListSw
            dateRange={dateRange}
            typeCheck={typeCheck}
            statusPatients={statusPatients}
            isOpen={isOpenDialogListSw}
            onClose={onCloseDialog}
          />
        )}
      </Suspense>
      <Box
        height="100%"
        borderColor="gray.200"
        borderWidth="1px"
        p={6}
        borderRadius="12px"
      >
        <Text fontSize="sm" lineHeight={5} color="gray.500">
          {title}
        </Text>
        {data.length < 1 && (
          <VStack flexGrow={1} py={12} align="center" justify="center">
            <Image src={networkImage} />
            <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
              No data available
            </Text>
          </VStack>
        )}
        {isLoaded && data.length > 0 && (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={data}
                  innerRadius={60}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  labelLine={false}
                >
                  {data.map((_, index) => (
                    <Cell
                      onClick={() => onOpenDialog(_.name)}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`cell-${index}`}
                      fill={DEFAULT_COLORS[index % DEFAULT_COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <Grid templateColumns="repeat(3, 1fr)" gap={1}>
              <GridItem>
                <VStack>
                  <HStack>
                    <Circle size="10px" bg="green.500" />
                    <Text fontSize="xs">Healthy</Text>
                  </HStack>
                  <Text fontSize="sm" fontWeight="bold">
                    {summary.healthy}
                  </Text>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack>
                  <HStack>
                    <Circle size="10px" bg="orange.500" />
                    <Text fontSize="xs">Attention</Text>
                  </HStack>
                  <Text fontSize="sm" fontWeight="bold">
                    {summary.handling}
                  </Text>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack>
                  <HStack>
                    <Circle size="10px" bg="purple.500" />
                    <Text fontSize="xs">Not measuring</Text>
                  </HStack>
                  <Text fontSize="sm" fontWeight="bold">
                    {summary.notMeasure}
                  </Text>
                </VStack>
              </GridItem>
            </Grid>
          </>
        )}
      </Box>
    </Skeleton>
  );
}

export default SmartwatchPie;
