import {
  Box,
  Text,
  VStack,
  Image,
  Flex,
  HStack,
  Circle,
  Skeleton,
} from '@chakra-ui/react';

import {formatDateTime} from 'lib/helper';

import networkImage from 'assets/images/network.svg';

import {
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Area,
} from 'recharts';

const DEFAULT_COLORS = [
  'var(--chakra-colors-teal-400)',
  'var(--chakra-colors-orange-500)',
  'var(--chakra-colors-purple-500)',
];

function ChartDetail({
  title,
  subtitle,
  data = [],
  dataKeys = ['value'],
  isLoaded = false,
}: {
  title: string;
  subtitle: string;
  data: any[];
  dataKeys: string[];
  isLoaded?: boolean;
}): JSX.Element {
  return (
    <Skeleton isLoaded={isLoaded}>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        borderColor="gray.200"
        borderWidth="1px"
        p={6}
        borderRadius="12px"
      >
        <Flex justify="space-between" align="start">
          <Flex direction="column">
            <Text fontSize="sm" lineHeight={5} color="gray.500">
              {title}
            </Text>
          </Flex>
        </Flex>
        {isLoaded && data.length === 0 && (
          <VStack flexGrow={1} py={12} align="center" justify="center">
            <Image src={networkImage} />
            <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
              No data available
            </Text>
          </VStack>
        )}
        {isLoaded && data.length > 0 && (
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              margin={{top: 10, right: 20, left: -20, bottom: 0}}
              data={data}
            >
              <defs>
                {dataKeys.map((dataKey, index) => (
                  <linearGradient
                    key={dataKey}
                    id={dataKey}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="0%"
                      stopColor={DEFAULT_COLORS[index]}
                      stopOpacity={0.5}
                    />
                    <stop
                      offset="68%"
                      stopColor={DEFAULT_COLORS[index]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                ))}
              </defs>
              <YAxis orientation="left" />
              <XAxis dataKey="name" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              {dataKeys.map((dataKey, index) => (
                <Area
                  key={dataKey}
                  dataKey={dataKey}
                  dot={{stroke: DEFAULT_COLORS[index], strokeWidth: 2}}
                  stroke={DEFAULT_COLORS[index]}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill={`url(#${dataKey})`}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Skeleton>
  );
}

export default ChartDetail;
