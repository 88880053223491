import {
  Box,
  Table,
  Grid,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Image,
  Text,
} from '@chakra-ui/react';
import {useTable, useSortBy} from 'react-table';
import {IconContext} from 'react-icons';
import {BiSort, BiSortDown, BiSortUp} from 'react-icons/bi';
import networkImage from 'assets/images/network.svg';
import {useNavigate} from 'react-router-dom';
import TableSkeleton from './table-skeleton';

function SortTable({
  columns,
  data,
  isLoaded = false,
  ...props
}: {
  columns: any;
  data: any;
  isLoaded?: boolean;
  [key: string]: any;
}): JSX.Element {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data}, useSortBy);
  const navigate = useNavigate();

  return (
    <Box {...props}>
      {!isLoaded && <TableSkeleton />}
      {isLoaded && data.length === 0 && (
        <VStack flexGrow={1} py={12} align="center" justify="center">
          <Image src={networkImage} />
          <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
            No data available
          </Text>
        </VStack>
      )}
      {isLoaded && data.length > 0 && (
        <TableContainer>
          <Table variant="simple" {...getTableProps()}>
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {/* eslint-disable-next-line */}
                        <IconContext.Provider
                          value={{style: {display: 'inline-flex'}}}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BiSortDown />
                            ) : (
                              <BiSortUp />
                            )
                          ) : (
                            <BiSort />
                          )}
                        </IconContext.Provider>
                        {/* eslint-enable-next-line */}
                      </span>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    // _hover={{
                    //   background: 'teal.500',
                    //   transition: 'all 0.5s ease',
                    //   color: 'white',
                    // }}
                    // style={{cursor: 'pointer'}}
                    {...row.getRowProps()}
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td
                          // onClick={() => {
                          //   /* eslint-disable */
                          //   navigate(
                          //     // @ts-ignore: Unreachable code error
                          //     `/patients-health-detail/${cell.row.original.userId}`,
                          //   );
                          //   /* eslint-enable */
                          // }}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default SortTable;
