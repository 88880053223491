import {AxiosResponse} from 'axios';

import client from 'lib/client';

import {ENDPOINT} from '../constants';

function listTypeCheck(): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listTypeCheck);
}

function listTypeCheckKiosk(): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listTypeCheckKiosk);
}

function listTypeCheckSw(): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listTypeCheckSw);
}

function listTypeProject(): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listTypeProject);
}

function listDevice(): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listDevice);
}

function listCategory(): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listCategory, {
    params: {
      page: 1,
      limit: 100,
    },
  });
}

function listPieKiosk(arg: any): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listPieKiosk, {
    params: {
      ...arg,
    },
  });
}

function listPieFatmor(arg: any): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listPieFatmor, {
    params: {
      ...arg,
    },
  });
}

function listPieSw(arg: any): Promise<AxiosResponse<any, any>> {
  return client.get(ENDPOINT.listPieSw, {
    params: {
      ...arg,
    },
  });
}

export {
  listTypeCheck,
  listDevice,
  listTypeCheckKiosk,
  listTypeProject,
  listTypeCheckSw,
  listCategory,
  listPieKiosk,
  listPieFatmor,
  listPieSw,
};
