import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Spacer,
  Text,
  Grid,
  GridItem,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import {SortTable, ErrorAlert} from 'common/components';
import {useEffect, useMemo} from 'react';

import {useListPieSw} from '../context/hooks';

function DialogListSw({
  isOpen,
  onClose,
  dateRange,
  typeCheck,
  statusPatients,
}: {
  isOpen: boolean;
  onClose: () => void;
  dateRange: any;
  typeCheck: any;
  statusPatients: any;
}): JSX.Element {
  const statusHealth =
    statusPatients === 'Healthy'
      ? 'normal'
      : statusPatients === 'Attention'
      ? 'attention'
      : 'not_measure';

  const {
    status,
    data: response,
    refetch,
    errorMessage,
  } = useListPieSw({
    typeCheck,
    dateRange,
    statusHealth,
  });

  const columns = useMemo(
    () => [
      {
        Header: 'No',
        id: 'no',
        accessor: (row: any, index: number): number => index + 1,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Result',
        accessor: 'value',
      },
    ],
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody px={6} py={38}>
          <Text mb={5}>{statusPatients}</Text>
          {status !== 'error' && (
            <SortTable
              isLoaded={status === 'success'}
              columns={columns}
              data={response?.data || []}
            />
          )}
          {status === 'error' && (
            <ErrorAlert message={errorMessage} onRetry={refetch} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default DialogListSw;
