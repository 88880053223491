export type Gender = 'male' | 'female';

export const GENDER = ['male', 'female'];

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
];

export const PAYMENT_METHOD_OPTIONS = [
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Transfer',
    value: 'transfer',
  },
];

export const PROJECTS = ['kiosk', 'fatmor', 'smartwatch'];

export const ENDPOINT = {
  listTypeCheckKiosk: 'dashboard-client/measurement/type-check/kiosk',
  listTypeCheck: 'dashboard-client/measurement/type-check/all',
  listTypeProject: 'dashboard-client/measurement/type-project',
  listDevice: 'dashboard-client/device-kiosk/get-list',
  listTypeCheckSw: 'dashboard-client/measurement/type-check/smartwatch',
  listCategory: 'dashboard-client/category/list',
  listPieKiosk: 'dashboard-client/patient-health/pie-kiosk-list',
  listPieFatmor: 'dashboard-client/patient-health/pie-fatmor-list',
  listPieSw: 'dashboard-client/patient-health/pie-smartwatch-list',
};

const NAMESPACE = 'COMMON';

/** ************************* For Actions *************************************** */
export const FETCH_LIST_TYPE_CHECK_KIOSK = `${NAMESPACE}/FETCH_LIST_TYPE_CHECK_KIOSK`;
export const FETCH_TYPE_CHECK_LIST = `${NAMESPACE}_FETCH_TYPE_CHECK_LIST`;
export const FETCH_DEVICE_LIST = `${NAMESPACE}_FETCH_DEVICE_LIST`;
export const FETCH_TYPE_PROJECT_LIST = `${NAMESPACE}_FETCH_TYPE_PROJECT_LIST`;
export const FETCH_TYPE_CHECK_SW = `${NAMESPACE}_FETCH_TYPE_CHECK_SW`;
export const FETCH_TYPE_CATEGORY = `${NAMESPACE}_FETCH_TYPE_CATEGORY`;
export const FETCH_PIE_KIOSK = `${NAMESPACE}_FETCH_PIE_KIOSK`;
export const FETCH_PIE_FATMOR = `${NAMESPACE}_FETCH_PIE_FATMOR`;
export const FETCH_PIE_SW = `${NAMESPACE}_FETCH_PIE_SW`;
