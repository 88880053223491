import {useGetQuery, IUseGetQuery} from 'lib/client/hooks';

import * as commonService from '../services';
import * as TYPES from '../constants';

export const useListTypeCheck = (): IUseGetQuery => {
  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: TYPES.FETCH_TYPE_CHECK_LIST,
    queryFn: async () => {
      const response = await commonService.listTypeCheck();
      return response.data;
    },
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListTypeCheckKiosk = (): IUseGetQuery => {
  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: TYPES.FETCH_LIST_TYPE_CHECK_KIOSK,
    queryFn: async () => {
      const response = await commonService.listTypeCheckKiosk();
      return response.data;
    },
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListTypeCheckSw = (): IUseGetQuery => {
  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: TYPES.FETCH_TYPE_CHECK_SW,
    queryFn: async () => {
      const response = await commonService.listTypeCheckSw();
      return response.data;
    },
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListTypeProject = (): IUseGetQuery => {
  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: TYPES.FETCH_TYPE_PROJECT_LIST,
    queryFn: async () => {
      const response = await commonService.listTypeProject();
      return response.data;
    },
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListDevice = (): IUseGetQuery => {
  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: TYPES.FETCH_DEVICE_LIST,
    queryFn: async () => {
      const response = await commonService.listDevice();
      return response.data;
    },
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListCategory = (): IUseGetQuery => {
  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: TYPES.FETCH_TYPE_CATEGORY,
    queryFn: async () => {
      const response = await commonService.listCategory();
      return response.data;
    },
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListPieKiosk = (params: any): IUseGetQuery => {
  const typeCheck = params?.typeCheck;
  const search = params?.search;
  const typeProject = 'kiosk';
  const startDate = params?.startDate;
  const endDate = params?.endDate;
  const selectDate = params?.selectDate;
  const dateRange = params?.dateRange;

  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: [
      TYPES.FETCH_PIE_KIOSK,
      search,
      typeCheck,
      dateRange,
      typeProject,
      startDate,
      endDate,
      selectDate,
    ],
    queryFn: async () => {
      const response = await commonService.listPieKiosk(params);
      return response.data;
    },
    enabled: !!typeCheck && !!dateRange,
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListPieSw = (params: any): IUseGetQuery => {
  const typeCheck = params?.typeCheck;
  const search = params?.search;
  const typeProject = 'smartwatch';
  const startDate = params?.startDate;
  const endDate = params?.endDate;
  const selectDate = params?.selectDate;
  const dateRange = params?.dateRange;

  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: [
      TYPES.FETCH_PIE_SW,
      search,
      typeCheck,
      dateRange,
      typeProject,
      startDate,
      endDate,
      selectDate,
    ],
    queryFn: async () => {
      const response = await commonService.listPieSw(params);
      return response.data;
    },
    enabled: !!typeCheck && !!dateRange,
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};

export const useListPieFatmor = (params: any): IUseGetQuery => {
  const typeCheck = params?.typeCheck;
  const search = params?.search;
  const typeProject = 'fatmor';
  const startDate = params?.startDate;
  const endDate = params?.endDate;
  const selectDate = params?.selectDate;
  const dateRange = params?.dateRange;

  const {data, status, errorMessage, isIdle, refetch} = useGetQuery({
    queryKey: [
      TYPES.FETCH_PIE_FATMOR,
      search,
      typeCheck,
      dateRange,
      typeProject,
      startDate,
      endDate,
      selectDate,
    ],
    queryFn: async () => {
      const response = await commonService.listPieFatmor(params);
      return response.data;
    },
    enabled: !!typeCheck && !!dateRange,
  });

  return {
    data,
    status,
    errorMessage,
    isIdle,
    refetch,
  };
};
