// import {ResponsiveContainer, Tooltip, PieChart, Pie, Cell} from 'recharts';

import networkImage from 'assets/images/network.svg';
import userdefault from 'assets/images/user.png';
import {
  Box,
  Skeleton,
  Text,
  VStack,
  Image,
  HStack,
  Circle,
  Grid,
  GridItem,
} from '@chakra-ui/react';

const DEFAULT_COLORS = [
  'var(--chakra-colors-orange-500)',
  'var(--chakra-colors-teal-400)',
  'var(--chakra-colors-purple-500)',
];

function ProfilePatient({
  title,
  data = {
    fullname: '',
    email: '',
    height: 0,
    weight: 0,
    gender: 'not defined',
    address: '',
    age: 0,
    avatar: '',
  },
  isLoaded = false,
}: {
  title: string;
  data: {
    fullname: string;
    email: string;
    height: number;
    weight: number;
    gender: string;
    address: string;
    age: number;
    avatar: string;
  };
  isLoaded?: boolean;
}): JSX.Element {
  return (
    <Skeleton isLoaded={isLoaded} height="100%">
      <Box
        height="100%"
        borderColor="gray.200"
        borderWidth="1px"
        p={6}
        borderRadius="12px"
      >
        <Text fontSize="sm" lineHeight={5} color="gray.500">
          {title}
        </Text>
        {isLoaded && data.fullname.length < 1 && (
          <VStack flexGrow={1} py={12} align="center" justify="center">
            <Image src={networkImage} />
            <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
              No data available
            </Text>
          </VStack>
        )}
        {isLoaded && data.fullname.length > 0 && (
          <Grid
            py={3}
            gap={2}
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(4, 1fr)',
            }}
          >
            <GridItem height="20%">
              <Image
                borderRadius="full"
                mt={2}
                boxSize="40px"
                src={data.avatar === '' ? userdefault : data.avatar}
              />
            </GridItem>
            <GridItem height="80%" colSpan={3}>
              <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                {data.fullname}
              </Text>
              <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                {data.email}
              </Text>
              <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                {data.height === undefined || null ? '' : `${data.height} cm`}
              </Text>
              <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                {data.weight === undefined || null ? '' : `${data.weight} kg`}
              </Text>
              {data.gender === null ? (
                ''
              ) : (
                <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                  {data.gender === 'male' ? 'Male' : 'Female'}
                </Text>
              )}
              {data.address === null ? (
                ''
              ) : (
                <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                  {data.address}
                </Text>
              )}
              {data.age === null ? (
                ''
              ) : (
                <Text fontSize="sm" lineHeight={5} color="gray.500" my={2}>
                  {data.age} years old
                </Text>
              )}
            </GridItem>
          </Grid>
        )}
      </Box>
    </Skeleton>
  );
}

export default ProfilePatient;
