import {
  background,
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import {useTable} from 'react-table';

import TableSkeleton from './table-skeleton';

function DataTablePatients({
  columns,
  data,
  isLoaded = false,
  ...props
}: {
  columns: any;
  data: any;
  isLoaded?: boolean;
  [key: string]: any;
}): JSX.Element {
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({columns, data});
  const navigate = useNavigate();

  return (
    <Box {...props}>
      {!isLoaded && <TableSkeleton />}
      {isLoaded && (
        <TableContainer>
          <Table variant="simple" {...getTableProps()}>
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    _hover={{
                      background: 'teal.500',
                      transition: 'all 0.5s ease',
                      color: 'white',
                    }}
                    style={{cursor: 'pointer'}}
                    {...row.getRowProps()}
                  >
                    {row.cells.map(cell => {
                      return (
                        <Td
                          onClick={() => {
                            /* eslint-disable */
                            if (
                              cell.column.Header !== 'Action' &&
                              cell.column.Header !== 'Photo'
                            ) {
                              navigate(
                                // @ts-ignore: Unreachable code error
                                `/patients-health-detail/${cell.row.original.userId}`,
                              );
                            }
                            /* eslint-enable */
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

export default DataTablePatients;
