import {Box, Image, Text, Skeleton, Flex} from '@chakra-ui/react';

import {VscTriangleUp, VscTriangleDown} from 'react-icons/vsc';

function HealthStat({
  title,
  value,
  icon,
  direction,
  change,
  isLoaded = false,
}: {
  title: string;
  value: number;
  direction: string;
  change: number;
  icon: string;
  isLoaded?: boolean;
}): JSX.Element {
  return (
    <Skeleton isLoaded={isLoaded}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        p={6}
        borderRadius="md"
        borderColor="gray.200"
        borderWidth="1px"
      >
        <Box display="flex" flexDirection="column">
          <Text fontSize="sm" lineHeight={5} mb={1}>
            {title}
          </Text>
          <Text fontSize="2xl" lineHeight={8} fontWeight="semibold">
            {value}
          </Text>
          <Flex direction="row">
            <Box my={1.5}>
              {direction === 'up' && (
                <VscTriangleUp size={10} color="green" fill="green" />
              )}{' '}
              {direction === 'down' && (
                <VscTriangleDown size={10} color="red" fill="red" />
              )}
            </Box>
            <Text fontSize="md" ml={1}>
              {change > 0 ? `${change}` : '-'}
            </Text>
          </Flex>
        </Box>
        <Image src={icon} alt="kiosk" mb={8} sizes="36px" />
      </Box>
    </Skeleton>
  );
}

export default HealthStat;
