import {Box, Image, Text, Skeleton} from '@chakra-ui/react';

function PatientsHealthStat({
  title,
  value,
  icon,
  isLoaded = false,
}: {
  title: string;
  value: number;
  icon: string;
  isLoaded?: boolean;
}): JSX.Element {
  return (
    <Skeleton isLoaded={isLoaded}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="start"
        p={6}
        borderRadius="md"
        borderColor="gray.200"
        borderWidth="1px"
      >
        <Box display="flex" flexDirection="column">
          <Text fontSize="sm" lineHeight={5} mb={1}>
            {title}
          </Text>
          <Text fontSize="2xl" lineHeight={8} fontWeight="semibold">
            {value}
          </Text>
        </Box>
        <Image src={icon} alt="patients" mb={8} sizes="36px" />
      </Box>
    </Skeleton>
  );
}

export default PatientsHealthStat;
