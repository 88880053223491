import {Suspense, lazy, useEffect} from 'react';
import shallow from 'zustand/shallow';
import {
  useLocation,
  useNavigate,
  Routes,
  Route,
  useRoutes,
} from 'react-router-dom';

import {useAuth} from 'lib/auth-provider/context';
import {useProfile} from 'lib/auth-provider/context/hooks';

import {FullPageSpinner} from 'common/components';

const LoginModule = lazy(() => import('./modules/login'));
const RegisterAdministratorModule = lazy(
  () => import('./modules/register-administrator'),
);
const RegisterCompanyModule = lazy(() => import('./modules/register-company'));
const PatientModule = lazy(() => import('./modules/patient'));
const PatientExistingModule = lazy(() => import('./modules/patients-existing'));
const VisitorModule = lazy(() => import('./modules/visitor'));
const CategoryModule = lazy(() => import('./modules/category'));
const CompanySettingModule = lazy(() => import('./modules/company-setting'));
const IoTSettingModule = lazy(() => import('./modules/iot-setting'));
const PackageModule = lazy(() => import('./modules/package'));
const MembershipModule = lazy(() => import('./modules/membership'));
const HistoryModule = lazy(() => import('./modules/history'));
const RecordModule = lazy(() => import('./modules/record-history'));
const FatmorPatientsModule = lazy(
  () => import('./modules/patients-every-device'),
);
const KioskPatientsModule = lazy(
  () => import('./modules/patients-every-device/kiosk'),
);
const SwPatientsModule = lazy(
  () => import('./modules/patients-every-device/smartwatch'),
);
const SummaryVisitorKioskModule = lazy(
  () => import('./modules/summary-visitor-kiosk'),
);
const SummaryVisitorFaceRecognitionModule = lazy(
  () => import('./modules/summary-visitor-face-recognition'),
);
const PatientsHealthModule = lazy(() => import('./modules/patients-health'));
const PatientsHealthDetailModule = lazy(
  () => import('./modules/patients-health-detail'),
);
const SummaryModule = lazy(
  () => import(/* webpackPrefetch: true */ './modules/summary'),
);
const AdditionalRecord = lazy(() => import('./modules/additional-record'));
const Demography = lazy(() => import('./modules/demography'));
const OpenAPI = lazy(() => import('./modules/open-api'));
const FullPageError = lazy(() => import('common/components/full-page-error'));

function App(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [getAuth] = useAuth(state => [state.getAuth], shallow);

  const {refetch: getProfile} = useProfile();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/summary');
    }
  }, [location, navigate]);

  useEffect(() => {
    const currentUser = getAuth();
    if (currentUser.token) {
      getProfile();
    }
  }, [getAuth, getProfile]);

  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Routes>
        <Route path="/login/*" element={<LoginModule />} />
        <Route
          path="/register-administrator/*"
          element={<RegisterAdministratorModule />}
        />
        <Route path="/register-company/*" element={<RegisterCompanyModule />} />
        <Route path="/patient/*" element={<PatientModule />} />
        <Route path="/patient-existing/*" element={<PatientExistingModule />} />
        <Route path="/visitor/*" element={<VisitorModule />} />
        <Route path="/category/*" element={<CategoryModule />} />
        <Route path="/summary/*" element={<SummaryModule />} />
        <Route path="/company-setting/*" element={<CompanySettingModule />} />
        <Route path="/iot-setting/*" element={<IoTSettingModule />} />
        <Route path="/iot-setting/:project/*" element={<IoTSettingModule />} />
        <Route path="/package/*" element={<PackageModule />} />
        <Route path="/membership/*" element={<MembershipModule />} />
        <Route path="/history/*" element={<HistoryModule />} />
        <Route
          path="/record-history/:patientsid/*"
          element={<RecordModule />}
        />
        <Route
          path="/summary-visitor-kiosk/*"
          element={<SummaryVisitorKioskModule />}
        />
        <Route
          path="/summary-visitor-face-recognition/*"
          element={<SummaryVisitorFaceRecognitionModule />}
        />
        <Route path="/patients-health/*" element={<PatientsHealthModule />} />
        <Route
          path="/patients-health-fatmor/*"
          element={<FatmorPatientsModule />}
        />
        <Route
          path="/patients-health-kiosk/*"
          element={<KioskPatientsModule />}
        />
        <Route path="/patients-health-sw/*" element={<SwPatientsModule />} />
        <Route
          path="/patients-health-detail/:patientsid/*"
          element={<PatientsHealthDetailModule />}
        />
        <Route path="/additional-record/*" element={<AdditionalRecord />} />
        <Route path="/demography/*" element={<Demography />} />
        <Route path="/open-api/*" element={<OpenAPI />} />
        <Route
          path="*"
          element={<FullPageError message="Page not found" errorCode={404} />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
